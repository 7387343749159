<script setup>
    import {useModal} from "../../stores/ModalStore.js";
    const modals = useModal();

    const props = defineProps({
        id: String
    })
</script>

<template>
    <component :is="modals.modal(id)" v-bind="modals.modalProps(id)"></component>
</template>

<style scoped>

</style>
